import React, { useRef, useState, useEffect } from 'react'
import useAuth from '../../hooks/useAuth'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-router-dom'
import {
  faArrowRight,
  faCheck,
  faChevronRight,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons'
import Contact from '../../components/Contact'
import { useTheme } from '../../context/ThemeProvider'
const Dashboard = () => {
  const { auth, setAuth, setShowLoader, showLoader } = useAuth()
  const axios = useAxiosPrivate()
  const theme = useTheme()

  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  }

  const [currentOrg, setCurrentOrg] = useState({})
  const [user, setUser] = useState([])
  const [token, setToken] = useState('')
  const [saiPolicy, setSaiPolicy] = useState([])
  const [swbPolicy, setSWBPolicy] = useState([])
  const [ohPolicy, setOHPolicy] = useState([])
  const [loadedPolicies, setLoadedPolicies] = useState(false)
  const navigate = useNavigate()

  const handleClick = (dpt, contact, policy = null) => {
    let url
    switch (dpt) {
      case 'SWB':
        if (contact.wbo !== 1) {
          return
        }
        url = `${process.env.REACT_APP_WBO}/authenticate-token?token=${token}`
        window.location.href = url
        break
      case 'OH':
        if (contact.oh !== 1) {
          return
        }

        setAuth({ ...auth, currentPolicy: policy })
        localStorage.setItem('currentPolicy', JSON.stringify(policy))
        navigate('/oh/dashboard')

        break

      case 'SAI':
        if (contact.sai !== 1 || !policy?.department) {
          return
        }
        url = `${process.env.REACT_APP_SAS}/authenticate-token?token=${token}`
        if (policy?.department === 'Mutual') {
          url = `${process.env.REACT_APP_MUTUAL}/authenticate-token?token=${token}`
        }

        window.location.href = url
        break
      default:
        break
    }
  }

  const PolicyStatus = ({ status }) => {
    let bg = ''
    let text = ''
    let title = ''
    let dot = ''
    switch (status) {
      case 'live':
        bg = 'bg-green-200'
        dot = 'bg-green-600'
        text = 'text-green-600'
        title = 'Active'
        break
      case 'previous':
        bg = 'bg-rose-300/30'
        dot = 'bg-rose-600/50'
        text = 'text-rose-600/50'
        title = 'Expired'
        break
      case 'expiring':
        bg = 'bg-amber-300/30'
        text = 'text-amber-600/60'
        dot = 'bg-amber-600/60'
        title = 'Expiring'
        break
      case 'accepted':
        bg = 'bg-amber-300/30'
        text = 'text-amber-600/60'
        dot = 'bg-amber-600/60'
        title = 'Pending'
        break
      default:
    }

    return (
      <div
        className={`rounded ${bg} w-1/2 p-2.5 flex justify-center items-center ${text} text-base`}
      >
        <span className={`w-2 h-2 block rounded-full ${dot} mr-3`}>&nbsp;</span>
        <span className="font-bold">{title}</span>
      </div>
    )
  }

  const Policy = ({ dpt, policy }) => {
    let text = ''
    switch (dpt) {
      case 'sai':
        text = `Manage your staff details, holidays ${
          policy.department !== 'Mutual' && policy.department !== 'GBS'
            ? 'and absence claims'
            : 'and payment requests'
        }.`
        break
      case 'swb':
        text = 'Click here to manage your Standalone Wellbeing Agreement'
        break
      case 'oh':
        text = 'Click here to create and manage your Pre-Employment Requests'
        break
      default:
        break
    }
    return (
      <>
        <p className="text-center text-base text-[#201E5F]/80 mt-4 lg:min-h-[100px]">
          {text}
        </p>
        <div className="flex justify-between mt-8 space-x-4">
          <div className="rounded bg-purple-300/30 w-1/2 p-2.5 text-sm flex justify-center items-center text-purple-800/50 xl:text-base font-bold">
            {policy?.policy_reference}
          </div>
          <PolicyStatus status={policy.policy_status} />
        </div>
      </>
    )
  }

  const NotAllowed = ({ dpt }) => {
    return (
      <>
        <p className="text-center text-base text-[#201E5F]/80 mt-4 lg:min-h-[100px]">
          You currently don't have access to this product. Please contact the
          main contact at your school/trust if you believe this is an error.
        </p>
        <div className="flex justify-between mt-8">
          <div className="rounded bg-rose-300/60 w-full text-sm p-2.5 flex justify-center items-center text-rose-600/60 xl:text-base font-bold cursor-not-allowed">
            You have no access to this product
          </div>
        </div>
      </>
    )
  }

  const PolicyOh = () => {
    return (
      <>
        <p className="text-center text-base text-[#201E5F]/80 mt-4 lg:min-h-[100px]">
          Click here to create and manage your Pre-Employment Requests
        </p>
        <div className="flex justify-center mt-8">
          <div
            className={`rounded bg-green-200 w-full p-2.5 flex justify-center items-center text-green-600 text-base`}
          >
            <span className="font-bold">Active</span>
          </div>
        </div>
      </>
    )
  }

  const NoPolicy = ({ dpt }) => {
    let text = ''
    switch (dpt) {
      case 'sai':
        text = "You currently don't have a staff absence cover."
        break
      case 'swb':
        text =
          'You do not have a subscription to one of our separate, standalone Wellbeing Schemes.'
        break
      case 'oh':
        text =
          'You do not have a subscription for our extended Occupational Health services.'
        break
      default:
        break
    }
    return (
      <>
        <p className="text-center text-base text-[#201E5F]/80 mt-4 lg:min-h-[100px]">
          {text}
        </p>
        <div className="flex justify-between">
          <p className="w-full text-sm p-2.5 text-center xl:text-base">
            Please contact your Account Manager to discuss any additional{' '}
            {dpt === 'oh' && 'Occupational Health'}
            {dpt === 'sai' && 'Staff Absence'}
            {dpt === 'swb' && 'Wellbeing'} requirements.
          </p>
        </div>
      </>
    )
  }

  const ShowSAIButton = ({ type, policy }) => {
    switch (type) {
      case 'policy':
        return <Policy dpt="sai" policy={policy} />
      case 'not-allowed':
        return <NotAllowed dpt="sai" />
      case 'no-policy':
        return <NoPolicy dpt="sai" />
      default:
        break
    }
  }
  const ShowSWBButton = ({ type, policy }) => {
    switch (type) {
      case 'policy':
        return <Policy dpt="swb" policy={policy} />
      case 'not-allowed':
        return <NotAllowed dpt="swb" />
      case 'no-policy':
        return <NoPolicy dpt="swb" />
      default:
        break
    }
  }
  const ShowOHButton = ({ type, policy }) => {
    if (!auth?.user?.oh) {
      return <NotAllowed dpt="oh" />
    } else if (ohPolicy) {
      return <PolicyOh policy={ohPolicy} />
    } else if (
      saiPolicy &&
      (saiPolicy.department === 'Mutual' || saiPolicy.department === 'SAS')
    ) {
      return <PolicyOh policy={saiPolicy} />
    } else {
      return <NoPolicy dpt="oh" />
    }
  }

  const getPolicys = async () => {
    setShowLoader(true)
    const response = await axios.post('/staff-hub/policy', {
      token: auth.token,
      orgId: auth.schools[0].id ?? auth?.schools[0].id,
      user: auth?.user?.contact_id,
    })
    setSaiPolicy(response.data.result.data.policies.sai)
    setSWBPolicy(response.data.result.data.policies.swb)
    setOHPolicy(response.data.result.data.policies.oh)
    setToken(response.data.result.data.token)

    localStorage.setItem('orgType', JSON.stringify(response.data.result.data.academy_id))

    setShowLoader(false)
  }

  useEffect(() => {
    if (!loadedPolicies) {
      setLoadedPolicies(true)
      getPolicys()
      localStorage.removeItem('currentPolicy')
    }
  }, [auth])

  return (
    <>
      <div className="mb-8 sm:mb-16">
        <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white text-center pt-12 ">
          Welcome Back, {auth?.user?.contact_name}
        </h3>
        <h4 className="text-lg font-medium text-white text-center">
          You are now viewing {auth?.schools[0]?.name}, which product would you
          like to view?
        </h4>
      </div>

      <div className="w-full max-w-7xl flex-col lg:flex-row flex space-y-10 lg:space-y-0 lg:space-x-5 xl:space-x-10">
        <div
          className={`w-full lg:w-1/3 bg-white shadow-lg rounded-md overflow-hidden flex flex-col-reverse lg:flex-row-reverse ${
            auth?.user?.sai === 1 && saiPolicy
              ? ' cursor-pointer'
              : ' cursor-not-allowed'
          }`}
          onClick={() => {
            saiPolicy && handleClick('SAI', auth.user, saiPolicy)
          }}
        >
          <div className="w-full p-8 lg:p-6 xl:p-8">
            <h3 className="text-center text-xl sm:text-2xl lg:text-xl text-[#201E5F] font-semibold">
              {saiPolicy?.department === 'GBS' ||
              saiPolicy?.department === 'Mutual'
                ? 'Staff Absence Agreement'
                : 'Staff Absence Policy'}
            </h3>
            <ShowSAIButton
              type={
                auth?.user?.sai
                  ? saiPolicy
                    ? 'policy'
                    : 'no-policy'
                  : 'not-allowed'
              }
              policy={saiPolicy}
            />
          </div>
        </div>

        <div
          className={`w-full lg:w-1/3 bg-white shadow-lg rounded-md overflow-hidden flex flex-col-reverse lg:flex-row-reverse ${
            auth?.user?.wbo === 1 && swbPolicy
              ? ' cursor-pointer'
              : ' cursor-not-allowed'
          }`}
          onClick={() => {
            swbPolicy && handleClick('SWB', auth.user)
          }}
        >
          <div className="w-full p-8 lg:p-6 xl:p-8">
            <h3 className="text-center text-xl sm:text-2xl lg:text-xl text-[#201E5F] font-semibold">
              Whole School Wellbeing
            </h3>
            <ShowSWBButton
              type={
                auth?.user?.wbo
                  ? swbPolicy
                    ? 'policy'
                    : 'no-policy'
                  : 'not-allowed'
              }
              policy={swbPolicy}
            />
          </div>
        </div>

        <div
          className={`w-full lg:w-1/3 bg-white shadow-lg rounded-md overflow-hidden flex flex-col-reverse lg:flex-row-reverse ${
            auth?.user?.oh === 1 && (ohPolicy || saiPolicy)
              ? ' cursor-pointer'
              : ' cursor-not-allowed'
          }`}
          onClick={() => {
            ohPolicy
              ? handleClick('OH', auth.user, ohPolicy)
              : saiPolicy && handleClick('OH', auth.user, saiPolicy)
          }}
        >
          <div className={`w-full p-8 lg:p-6 xl:p-8 ${
            auth?.user?.oh === 1 && (ohPolicy || saiPolicy)
              ? ' cursor-pointer'
              : ' cursor-not-allowed'
          }`}>
            <h3 className="text-center text-2xl lg:text-xl text-[#201E5F] font-semibold">
              Pre-Placement Requests
            </h3>
            <ShowOHButton
              type={
                auth?.user?.oh
                  ? ohPolicy
                    ? 'policy'
                    : 'no-policy'
                  : 'not-allowed'
              }
              policy={ohPolicy}
            />
          </div>
        </div>
      </div>

      <Contact />
    </>
  )
}

export default Dashboard