import React from 'react'
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import SlideOut from '../../../components/SlideOut'
import ViewRequest from '../../../components/ViewRequest'
import Contact from '../../../components/Contact'
import useAuth from '../../../hooks/useAuth'
import Pagination from '../../../components/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons'

const Dashboard = () => {
  const { auth, setAuth, setShowLoader } = useAuth()
  const [recordsPerPage] = useState(10)
  const [serviceRequests, setServiceRequests] = useState('')
  const [originalRecords, setOriginalRecords] = useState([])
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [filteredRecords, setFilteredRecords] = useState([])
  const [currentRecords, setCurrentRecords] = useState([])
  const [nPages, setNumberOfPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [orgType, setOrgType] = useState('')
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const axios = useAxiosPrivate()

  const openRequest = (request = null) => {
    setSelectedRequest(request)
    setOpen(true)
  }

  const handleViewRequest = (request) => {
    setSelectedRequest(request)
    setModalOpen(true)
  }

  
  const closeModal = () => {
    setModalOpen(false)
    setSelectedRequest(null)
  }


  const getServiceRequests = async () => {
    setShowLoader(true)
    try {
      await axios
        .get(
          `/service-requests/${auth.currentPolicy.policy_id}/${auth.user.contact_id}`
        )
        .then((response) => {
          if (response.data.requests) {
            const requests = response.data.requests
            setServiceRequests(requests)
            setFilteredRecords(requests)
            setOriginalRecords(requests)
            setCurrentRecords(
              requests.slice(indexOfFirstRecord, indexOfLastRecord)
            )
            setNumberOfPages(Math.ceil(requests.length / recordsPerPage))
          }
        })
        .catch((e) => {})
        .finally(() => {
          setShowLoader(false)
      
        })
    } catch (error) {
      console.error('Error fetching service requests', error)
    }
  }

  const handleRequestChange = () => {
    getServiceRequests()
  }

  const handleSearch = (handle) => {
    setIndexOfFirstRecord(0)
    setIndexOfLastRecord(10)
    setCurrentPage(0)
    setCurrentRecords(filteredRecords.slice(0, 10))

    let searchValue = handle.target.value
    let searchString = searchValue.trim().toLowerCase()
    if (searchString.length > 0) {
      let records = serviceRequests.filter((e) => {
        return (
          e.reference.toLowerCase().match(searchString) ||
          e.title.toLowerCase().match(searchString)
        )
      })
      setFilteredRecords(records)
      setCurrentRecords(records.slice(indexOfFirstRecord, indexOfLastRecord))
      setNumberOfPages(Math.ceil(records.length / recordsPerPage))
    } else {
      setFilteredRecords(serviceRequests)
      setCurrentRecords(
        serviceRequests.slice(indexOfFirstRecord, indexOfLastRecord)
      )
      setNumberOfPages(Math.ceil(serviceRequests.length / recordsPerPage))
    }
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRecords(filteredRecords.slice(indexFirst, indexLast))
    }
  }

  const statusClass = (status) => {
    if (typeof status !== 'string') {
      return 'bg-gray-200 text-gray-800'
    }

    switch (status.toLowerCase()) {
      case 'form completed - awaiting result':
      case 'no barrier to work':
      case 'no action required':
        return 'bg-green-200 text-green-800 border-green-400'
      case 'awaiting form completion':
      case 'referral needed':
      case 'in progress':
        return 'bg-amber-200 text-amber-800 border-amber-400'
      case 'closed':
        return 'bg-rose-200 text-rose-800 border-rose-400'
      default:
        if (status.toLowerCase().startsWith('form completed -')) {
          return 'bg-green-200 text-green-800 border-green-400'
        } else {
          return 'bg-gray-200 text-gray-800'
        }
    }
  }

  useEffect(() => {
    if (auth?.currentPolicy) {
      getServiceRequests()
    }

    setOrgType(localStorage.getItem('orgType'))
  }, [auth])

  return (
    <div className="">
      <div className="mb-8 sm:mb-10">
        <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white text-center pt-12">
          Pre-Placement Questionnaires (PPQ)
        </h3>
        <h4 className="text-lg font-medium text-white text-center">
          Create and Manage your Pre-Placement Questionnaire Requests here.
        </h4>
      </div>
      <div className="bg-white rounded-md p-4 w-full mx-auto shadow-md">
        <div className="pb-4 flex items-end justify-between ">
          <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md h-10 ">
            <div className="flex items-center pl-3 pointer-events-none text-gray-600">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                onClick={handleSearch}
              />
            </div>
            <input
              type="text"
              id="table-search"
              onChange={handleSearch}
              className="bg-gray-50 w-80 pl-6 "
              placeholder="Search Requests"
            />
          </div>
          <div className="mt-4 hover:shadow-md">
            <button
              type="submit"
              className="button border-[#02ADAD]"
              onClick={() => openRequest()}
            >
              <span className="text-[#02ADAD]">Submit Request</span>
              <span className="bg-[#02ADAD]">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </button>
          </div>
        </div>
        <div className="overflow-hidden rounded-lg mt-4">
          <table className="table-main">
            <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
              <tr className="bg-[#02ADAD]">
                <th scope="col" className="px-6 py-4">
                  Reference
                </th>
                <th scope="col" className="px-6 py-4">
                  Staff Member
                </th>
                <th scope="col" className="px-6 py-4">
                  Start Date
                </th>
                <th scope="col" className="px-6 py-4">
                  Status
                </th>
                <th scope="col" className="px-6 py-4">
                  Created On
                </th>
                <th scope="col" className="px-6 py-4">
                  Additional Info
                </th>
                <th scope="col" className="px-6 py-4">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 ? (
                currentRecords.map((item, index) => {
                  const requestInfo = JSON.parse(
                    item.request_information || '{}'
                  )
                  const staffDetails = requestInfo.staff_details || {}

                  return (
                    <tr
                      key={index}
                      className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600"
                    >
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {item.reference}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {staffDetails.staff_name || 'N/A'}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {staffDetails.start_date
                          ? moment(staffDetails.start_date).format('DD/MM/YYYY')
                          : 'N/A'}
                      </td>
                      <td className="px-2 text-xs py-3">
                        <span
                          className={`${statusClass(
                            item.request_status
                          )} rounded-full px-2 py-1 capitalize font-medium`}
                        >
                          {item.request_status}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {moment(item.created_at).format('DD/MM/YYYY')}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        {item.description}
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 font-medium">
                        <button className="text-blue-500" onClick={() => handleViewRequest(item)}>View</button>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <p className="no-records">No Requests found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRecords.length}
          />
        </div>
      </div>
      <div className="rounded-md bg-[#FFECD1] p-4 mt-16 border border-[#F8CF94]">
        <div className="flex">
          <div className="flex-shrink-0">
            {/* <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" /> */}
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-[#B57B29]">
              <strong>Please Note:</strong> All wellbeing service requests are confidential. Therefore any requests for access made by them
              will not show below. Presented here are the Pre-Placement
              Questionnaires created by you.
            </p>
          </div>
        </div>
      </div>
      <SlideOut
        open={open}
        setOpen={setOpen}
        auth={auth}
        requestData={selectedRequest}
        orgType={orgType}
      />

      <ViewRequest
        isOpen={modalOpen}
        onClose={closeModal}
        viewingRequest={selectedRequest}
      />
      <Contact />
    </div>
  )
}

export default Dashboard
