import React, { useRef, useState, useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import { useNavigate, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import Contact from '../components/Contact'
import { Select } from '@headlessui/react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useTheme } from '../context/ThemeProvider';
const AccountSelect = ({ data }) => {
  const { auth, setAuth, setShowLoader } = useAuth()
  const axios = useAxiosPrivate();
  const navigate = useNavigate()
  const location = useLocation()
  const emailRef = useRef()
  const errRef = useRef()
  const [schools, setSchools] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('')
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')

  const [errMsg, setErrMsg] = useState('')
  const theme = useTheme();

  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  };
 

  useEffect(() => {
   if(auth)
   {
      setSchools(auth.schools)
      setUser(auth.user)
      setSelectedSchool(JSON.stringify(auth.schools[0]))
   }
  }, [auth])

  const createSession = async () => {
    setShowLoader(true)
    let school = JSON.parse(selectedSchool);
    let orgId = school.id;
    await axios.post('/staff-hub/session/create', {contact: auth.user, orgId: orgId, schools:auth.schools }).then(function(response){
      setShowLoader(false)
      setAuth((prev) => {
        return { ...prev, user: response.data.result.user, schools: response?.data.result?.schools }
      });
      navigate('/dashboard');
    }).catch(e => {
      console.error(e)
    })
  }


  return (
    <>
    <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-8 sm:mb-16">
      Select Your Account
    </h3>
    <div className=" w-full bg-white shadow-lg rounded-md overflow-hidden max-w-4xl flex flex-col-reverse lg:flex-row-reverse">
      <div className="w-full lg:w-7/12 p-6 lg:p-12">
          <div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-slate-500"
              >
                Select Account
              </label>
              <div className="">
                <Select
                  name="status"
                  aria-label="Project status"
                  defaultValue={JSON.stringify(selectedSchool)}
                  onChange={(e) => {
                    setSelectedSchool(e.target.value)
                  }}
                  className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                >
                  {/* <option value="active">Active</option> */}
                  {schools?.map((school) => {
                      return (
                        <option
                          key={school.id}
                          value={JSON.stringify(school)}
                        >
                          {school.name}
                        </option>
                      )
                    })}
                </Select>
              </div>
            </div>
          </div>

          <div className='mt-4'>
            <button type="submit" className="button" style={{borderColor: style.color}} onClick={() => createSession()}>
              <span className="" style={{color: style.color}}>Select</span>
              <span className="" style={{backgroundColor: style.backgroundColor}}>
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </button>
          </div>

        <p className="mt-10 text-center text-sm text-gray-500">
          {/* Not a member?{' '} */}
          {/* <a href="#" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Start a 14 day free trial
            </a> */}
        </p>
      </div>

      <div className="w-full lg:w-5/12 bg-gray-200/70 flex flex-col p-6 lg:p-10">
        <h2 className="text-slate-700 font-bold text-lg sm:text-xl lg:text-2xl leading-6 mb-4 sm:mb-6">
          You have access to more than one account, please select this now.
        </h2>
      </div>
    </div>
    <Contact />
  </>
    )
    
}

export default AccountSelect
