/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react'
import { faCircleExclamation, faCheck, faTimes, faArrowRight, faChevronRight, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from '../context/ThemeProvider'

import { redirect, useNavigate, useSearchParams, useParams } from 'react-router-dom'
import axios from '../api/axios'

const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,12})$/
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/
const CREATE_URL = 'v2/staff-hub/create-password'
const GET_EMAIL = 'v2/staff-hub/get-email-from-token'

const CreatePassword = () => {
  let [searchParams] = useSearchParams()
  const theme = useTheme();
  const style = {
    backgroundColor: theme.backgroundColor,
    color: theme.textColor,
  };
  const { token } = useParams();
  const navigate = useNavigate()

  const emailRef = useRef()
  const passwordRef = useRef()
  const errRef = useRef()

  const [email, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false)

  const [pwd, setPwd] = useState('')
  const [validPwd, setValidPwd] = useState(false)
  const [pwdFocus, setPwdFocus] = useState(false)

  const [matchPwd, setMatchPwd] = useState('')
  const [validMatch, setValidMatch] = useState(false)
  const [matchFocus, setMatchFocus] = useState(false)

  const [errMsg, setErrMsg] = useState('')

  useEffect(() => {
    axios.get(GET_EMAIL, {
      params: {
        token: token,
        type: '',
      }
    }).then(function (response) {

      if(response.data.error) { navigate('/', { replace: true }) }

      let repEmail = response.data.result.email

      setEmail(repEmail)
      const result = EMAIL_REGEX.test(repEmail)
      setValidEmail(result)

      emailRef.current.focus()
      setErrMsg('')
    })
  }, [token])

  useEffect(() => {
    const result = PWD_REGEX.test(pwd)
    setValidPwd(result)
    const match = pwd === matchPwd
    setValidMatch(match)
  }, [pwd, matchPwd])

  useEffect(() => {
    setErrMsg('')
  }, [pwd, matchPwd])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const v1 = PWD_REGEX.test(pwd)
    if (!v1) {
      setErrMsg('Invalid Entry')
      return
    }

    try {
      const response = await axios.post(CREATE_URL, {
        contact_reset: token,
        contact_email: email,
        contact_password: pwd,
        password_confirmation: matchPwd,
      })
        navigate('/', { replace: true })
    } catch (error) {
      if (!error?.response) {
        setErrMsg('No Server Response')
      } else if (error.response?.status === 422) {
        setErrMsg('The email provided already exists.')
      } else {
        setErrMsg('Error Occured. Could not Reset Password.')
      }
    }
  }

  return (
        <>
          <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white mb-8 sm:mb-16">
            Create Passsword
          </h3>
          <div className="w-full max-w-4xl flex justify-center flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-7/12 bg-white shadow-lg rounded-md overflow-hidden">
              <div className="w-full p-6 lg:p-12">
                <form onSubmit={handleSubmit} method="POST" className="space-y-4 sm:space-y-6">
                  <div className='relative'>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-slate-500">
                        Email address
                      </label>
                      <div>
                        <input type='email'
                        id='email'
                        ref={emailRef}
                        value={email}
                        disabled
                        aria-describedby='emailnote'
                        placeholder='Email'
                        autofill='false'
                        className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                        />
                      </div>
                    </div>

                    <span className={validEmail ? 'valid input-check !right-[0.5rem] !top-[30px]' : 'hide input-check !right-[0.5rem] !top-[30px]'}>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validEmail || !email ? 'hide input-check !right-[0.5rem] !top-[30px]' : 'invalid input-check !right-[0.5rem] !top-[30px]'}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                    <p id='emailnote' className={emailFocus && email && !validEmail ? 'instructions' : 'offscreen'}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      Invalid email address.
                      <br /> Valid email addresses can contain only letters, numbers, '@' and '.'
                    </p>
                  </div>

                  <div className='relative'>
                    <div>
                      <label htmlFor="pwd" className="block text-sm font-medium text-slate-500">
                        Password
                      </label>
                      <div>
                        <input 
                        type="password"
                        id="pwd"
                        ref={passwordRef}
                        onChange={(e) => setPwd(e.target.value)}
                        required
                        aria-invalid={validPwd ? 'false' : 'true'}
                        aria-describedby="pwdnote"
                        placeholder="Password"
                        onFocus={() => setPwdFocus(true)}
                        onBlur={() => setPwdFocus(false)}
                        className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                        />
                      </div>
                    </div>

                    <span className={validPwd ? 'valid input-check !right-[0.5rem] !top-[30px]' : 'hide input-check !right-[0.5rem] !top-[30px]'}>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validPwd || !pwd ? 'hide input-check !right-[0.5rem] !top-[30px]' : 'invalid input-check !right-[0.5rem] !top-[30px]'}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                    <p id='pwdnote' className={pwdFocus && pwd && !validPwd ? 'instructions' : 'offscreen'}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      8 to 24 characters. <br />
                      Must include uppercase and lowercase letters, a number and a
                      special character. <br />
                      Allowed special characters:
                      <span aria-label="exclamation mark"> !</span>
                      <span aria-label="at symbol"> @</span>
                      <span aria-label="hashtag"> #</span>
                      <span aria-label="dollar sign"> $</span>
                      <span aria-label="percent"> %</span>
                    </p>
                  </div>

                  <div className='relative'>
                    <div>
                      <label htmlFor="confirm_pwd" className="block text-sm font-medium text-slate-500">
                        Confirm Password
                      </label>
                      <div>
                        <input
                        type="password"
                        id="confirm_pwd"
                        onChange={(e) => setMatchPwd(e.target.value)}
                        required
                        aria-invalid={validMatch ? 'false' : 'true'}
                        aria-describedby="confirmnote"
                        placeholder="Confirm Password"
                        onFocus={() => setMatchFocus(true)}
                        onBlur={() => setMatchFocus(false)}
                        className="text-base appearance-none border rounded w-full py-2 px-3 text-gray-700 "
                        />
                      </div>
                    </div>

                    <span className={validMatch ? 'valid input-check !right-[0.5rem] !top-[30px]' : 'hide input-check !right-[0.5rem] !top-[30px]'}>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>
                    <span className={validMatch || !matchPwd ? 'hide input-check !right-[0.5rem] !top-[30px]' : 'invalid input-check !right-[0.5rem] !top-[30px]'}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                    <p id='confirmnote' className={matchFocus && matchPwd && !validMatch ? 'instructions' : 'offscreen'}>
                      <FontAwesomeIcon icon={faCircleExclamation} />
                      Must match the first password input.
                    </p>
                  </div>

                  <div>
                    <button type="submit" disabled={!validPwd || !validMatch} className="button" style={{borderColor: !validPwd || !validMatch? 'gray' : style.backgroundColor, cursor: !validPwd || !validMatch? 'not-allowed' : 'pointer'}}>
                      <span style={{ color: !validPwd || !validMatch? 'gray' :  style.color}}>Create Password</span>
                      <span style={{backgroundColor: !validPwd || !validMatch? 'gray' :  style.backgroundColor}}>
                        <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
  )
}

export default CreatePassword
